<template>
  <div>
    <div class="ps-breadcrumb">
      <div class="ps-container">
        <ul class="breadcrumb">
          <li><router-link :to="{ name: 'home' }">Home</router-link></li>
          <li>
            <router-link :to="{ name: 'shop_products' }">Shop</router-link>
          </li>
          <li><a href="">Checkout</a></li>
          <li>Billing Information</li>
        </ul>
      </div>
    </div>
    <div class="ps-checkout ps-section--checkout">
      <div class="container">
        <div v-if="type != 'express'" class="ps-section__header">
          <h1>Billing Information</h1>
        </div>
        <div class="ps-section__content">
          <form v-if="cart.cartCount > 0" class="ps-form--checkout" action="/" method="post">
            <div class="row">
              <div class="col-12 mb-1" v-if="error">
                {{ error }}
              </div>
              <div class="col-xl-7 col-lg-8 col-md-12 col-sm-12">
                <div v-if="type != 'express'" class="ps-form__billing-info">
                  <h3 class="ps-form__heading">Billing Address</h3>
                  <div class="my-3">
                    <div class="form-check mb-4 p-4" v-for="billingAdr in billingAddresses">
                      <input id="billing_address" name="billingAddress" type="radio" class="form-check-input"
                        v-model="billingAddress" :value="billingAdr.id" v-on:change="toggleSelectedBillingAddress" />
                      <label class="form-check-label pl-4" for="shipping_address">
                        {{ billingAdr.contact_name }}
                        {{ billingAdr.street }}
                        {{ billingAdr.city }}
                        {{ billingAdr.province }}
                        {{ billingAdr.country }}
                      </label>
                    </div>
                  </div>
                  <div class="form-check mb-4 p-4">
                    <input class="form-check-input" name="billingAddress" type="radio" id="new_billing_address"
                      v-model="billingAddress" value="new_billing_address" v-on:change="toggleAddBillingAddress" />
                    <label class="form-check-label pl-4" for="new_billing_address">Add new billing address?</label>
                  </div>
                  <div class="my-3" v-if="canAddBillingAddress">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>First Name</label>
                          <input class="form-control" type="text" v-model="billing.first_name" placeholder="" />
                          <small v-if="errors && errors.first_name" class="country_error text-danger">
                            {{ errors.first_name[0] }}
                          </small>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Last Name</label>
                          <input class="form-control" type="text" v-model="billing.last_name" placeholder="" />
                          <small v-if="errors && errors.last_name" class="last_name_error text-danger">
                            {{ errors.last_name[0] }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Email Address<sup>*</sup> </label>
                      <div class="form-group__content">
                        <input class="form-control" v-model="billing.email" type="email" />
                        <small v-if="errors && errors.email" class="email_error text-danger">
                          {{ errors.email[0] }}
                        </small>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Address<sup>*</sup> </label>
                      <div class="form-group__content">
                        <input class="form-control" v-model="billing.street" type="hidden" />
                        <GmapAutocomplete class="form-control" v-if="!loadingForm" @place_changed="setBillingPlace" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Country</label>
                          <select v-model="billing.country" class="form-control">
                            <option :value="country.id" v-for="country in countries">
                              {{ country.name }}
                            </option>
                          </select>
                          <small v-if="errors && errors.country" class="country_error text-danger">
                            {{ errors.country[0] }}
                          </small>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>City/Town</label>
                          <input class="form-control" type="text" v-model="billing.city" placeholder="" />
                          <small v-if="errors && errors.city" class="city_error text-danger">
                            {{ errors.city[0] }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Phone<sup>*</sup> </label>
                      <div class="form-group__content">
                        <input class="form-control" v-model="billing.phone_number" type="text" />
                        <small v-if="errors && errors.phone_number" class="city_error text-danger">
                          {{ errors.phone_number[0] }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="hasProduct" class="ps-form__billing-info">
                  <h3 class="mt-40">Zimbabwe Delivery Options</h3>

                  <!--                  <div class="form-check mb-1 p-4">-->
                  <!--                    <input class="form-check-input" name="deliveryMethod" type="radio" id="delivery"-->
                  <!--                           v-model="deliveryMethod" value="shipping" v-on:change="toggleDeliveryMethod" />-->
                  <!--                    <label class="form-check-label pl-4" for="delivery">Delivery?</label>-->
                  <!--                  </div>-->
                  <div class="form-check mb-1 p-4" v-for="method in deliveryMethods">
                    <input class="form-check-input" name="deliveryMethod" type="radio" :id="method.slug"
                      v-model="deliveryMethod" :value="method" v-on:change="toggleDeliveryMethod" />
                    <label class="form-check-label pl-4" :for="method.slug">{{ method.name }}?</label>
                  </div>
                </div>

                <div class="ps-form__billing-info" v-if="isShipping">
                  <h3 class="ps-form__heading">Recipient Address</h3>

                  <div v-if="type != 'express'" class="form-check mb-4 p-4">
                    <input class="form-check-input" name="billing_is_shipping" type="checkbox" id="billing_is_shipping"
                      v-model="billingIsShipping" value="billing_is_shipping"
                      v-on:change="toggleBillingIsShippingAddress" />
                    <label class="form-check-label pl-4" for="billing_is_shipping">Same as billing address?</label>
                  </div>

                  <div class="my-3" v-if="!billingIsShipping">
                    <div class="form-check mb-4 p-4" v-for="address in addresses">
                      <input id="shipping_address" name="shippingAddress" type="radio" class="form-check-input"
                        v-model="shippingAddress" :value="address.id" v-on:change="toggleSelectedAddress" />
                      <label class="form-check-label pl-4" for="shipping_address">
                        {{ address.contact_name }}
                        {{ address.street }}
                        {{ address.city }}
                        {{ address.province }}
                        {{ address.country }}
                      </label>
                    </div>
                  </div>
                  <div class="form-check mb-4 p-4" v-if="!billingIsShipping">
                    <input class="form-check-input" name="shippingAddress" type="radio" id="new_address"
                      v-model="shippingAddress" value="new" v-on:change="toggleAddAddress" />
                    <label class="form-check-label pl-4" for="new_address">Add recipient address?</label>
                  </div>
                  <div class="my-3" v-if="canAddAddress && !billingIsShipping">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>First Name</label>
                          <input class="form-control" type="text" v-model="shipping.first_name" placeholder="" />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Last Name</label>
                          <input class="form-control" type="text" v-model="shipping.last_name" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Email Address<sup>*</sup> </label>
                      <div class="form-group__content">
                        <input class="form-control" v-model="shipping.email" type="email" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Address<sup>*</sup> </label>
                      <div class="form-group__content">
                        <input class="form-control" v-model="shipping.street" type="hidden" />
                        <GmapAutocomplete class="form-control" v-if="!loadingForm" @place_changed="setShippingPlace" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>Country</label>
                          <select disabled v-model="shipping.country" class="form-control">
                            <option :value="country.id" v-for="country in countries">
                              {{ country.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label>City/Town</label>
                          <input class="form-control" type="text" v-model="shipping.city" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Phone<sup>*</sup> </label>
                      <div class="form-group__content">
                        <input class="form-control" v-model="shipping.phone_number" type="text" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ps-form__billing-info mt-3" v-if="isPickUp">
                  <h3 class="ps-form__heading">Pick Up Details</h3>

                  <div class="form-group mb-4 p-3">
                    <label class="form-label" for="billing_is_shipping">Shop</label>
                    <select class="form-control" name="pickup_shop" id="pickup_shop" v-model="selectedPickUpShopId"
                      v-on:change="showShopAddress">
                      <option :value="shopAddress.id" v-for="shopAddress in shopAddresses">
                        {{ shopAddress.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-check mb-4" v-if="pickUpShop && selectedPickUpShopId">
                    <label class="form-check-label pl-4" for="shipping_address">
                      {{ pickUpShop.street }}, {{ pickUpShop.city }},
                      {{ pickUpShop.province }},
                      {{ pickUpShop.country }}
                    </label>
                  </div>
                </div>

                <div class="ps-form__billing-info">
                  <h3 class="mt-40">Addition information</h3>
                  <div class="form-group">
                    <label>Order Notes</label>
                    <div class="form-group__content">
                      <textarea class="form-control" v-model="order.order_notes" rows="7"
                        placeholder="Notes about your order, e.g. special notes for delivery."></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-4 col-md-12 col-sm-12">
                <div class="ps-form__total">
                  <h3 class="ps-form__heading">Your Order</h3>
                  <div class="card">
                    <div class="card-body">
                      <dl class="dlist-align" v-for="cartItem in cart.cartItems" :key="cartItem.id">
                        <dt>{{ cartItem.title }} × {{ cartItem.quantity }}</dt>
                        <dd class="text-right">
                          {{ cartItem.total | toCurrency }}
                        </dd>
                      </dl>
                      <dl class="dlist-align">
                        <dt>Subtotal:</dt>
                        <dd class="text-right text-danger">
                          {{ cart.total | toCurrency }}
                        </dd>
                      </dl>
                      <dl class="dlist-align">
                        <dt>Total:</dt>
                        <dd class="text-right text-dark b">
                          <strong>{{ cart.total | toCurrency }}</strong>
                        </dd>
                      </dl>
                      <hr />

                      <div class="row">
                        <div v-if="this.type === 'express'" class="col-12">
                          <a class="ps-btn ps-btn--express ps-btn--fullwidth" @click.prevent="saveOrder()">
                            {{
            isLoading ? "Saving..." : "Pay with voucher"
          }}
                            <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
                            <div v-if="isLoading" class="spinner-border text-light" role="status"></div>
                          </a>
                        </div>
                        <div v-else class="col-12">
                          <a class="ps-btn ps-btn--fullwidth" @click.prevent="saveOrder()">
                            {{
            isLoading ? "Saving..." : "Continue to Checkout"
                            }}
                            <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
                            <div v-if="isLoading" class="spinner-border text-light" role="status"></div>
                          </a>
                        </div>
                        <div class="col-12">
                          <a class="ps-btn ps-btn--fullwidth mt-5" href="/shop">
                            Continue Shopping
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- card-body.// -->
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div v-else class="text-danger text-center h2">Cart is empty</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/mixins/global.js";
import { mapState } from "vuex";
import CreateAddress from "../Profile/Address/Create";
import AddressForm from "../Profile/Address/AddressForm";
// import PlaceForm from "../../components/PlaceForm.vue";
// import GoogleMap from "@/components/GoogleMap.vue";
export default {
  components: { AddressForm, CreateAddress },
  data() {
    return {
      deliveryMethods: [],
      shopAddresses: Object,
      deliveryMethod: null,
      useSaved: false,
      selectedAddress: null,
      addresses: Object,
      billingAddresses: Object,
      billingAddress: "new_billing_address",
      selectedBillingAddress: null,
      canAddAddress: true,
      canAddBillingAddress: true,
      shippingAddress: "new",
      billingIsShipping: false,
      cartTotal: 0,
      totalCartItems: 0,
      isLoading: false,
      pickUpShop: Object,
      selectedPickUpShopId: null,
      countries: [],
      error: null,
      errors: null,
      billingPlace: {},
      shippingPlace: {},
      type: "",
      billing: {
        street: "",
        country: "",
        city: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
      },
      shipping: {
        street: "",
        country: "234",
        city: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
      },
      order: {
        order_notes: "",
        order_items: [],
      },
      loadingForm: false,
      // hasProduct: false,
    };
  },
  mounted() {
    this.type = this.$route.params.type;

    Promise.all([
      this.getBillingAddress(),
      this.getDeliveryMethods(),
      this.getShippingAddress(),
      this.getCountries()
    ]).then(() => {
    }).catch((error) => {
      console.error("Error loading data:", error);
    });
  },

  computed: {
    ...mapState({
      cart: (state) => state.cart,
      auth: (state) => state.auth,
    }),
    hasProduct() {
      for (const item of this.cart.cartItems) {
        if (item.type === 'product') {
          return true;
        }
      }
      return false;
    },
    isShipping() {
      return this.deliveryMethod && this.deliveryMethod.slug === "delivery";
    },
    isPickUp() {
      return this.deliveryMethod && this.deliveryMethod.slug === "pick-up";
    },
  },
  mixins: [global],
  methods: {
    setBillingPlace(place) {
      this.billingPlace = place;
      this.billing.street = place.formatted_address;
      const addressComponents = place.address_components;
      if (addressComponents[2]) {
        this.billing.city = addressComponents[2]["short_name"];
      }
      if (addressComponents[5]) {
        const country = this.countries.find(
          (country) =>
            country.code.toLowerCase() ===
            addressComponents[4]["short_name"].toLowerCase()
        );
        if (country) {
          this.billing.country = country.id;
        }
      }
      console.log(place);
      console.log(this.billingPlace);
    },

    setShippingPlace(place) {
      this.shippingPlace = place;
    },
    getCountries() {
      axios.get(`countries`).then((response) => {
        this.countries = response.data.filter(
          (country) => country.zip && country.code
        );
      });
    },

    getDeliveryMethods() {
      this.$loading(true);
      axios
        .get(`delivery_methods?shop=${this.cart.shopId}`)
        .then((response) => {
          this.deliveryMethods = response.data;
          if (this.deliveryMethods.length === 1) {
            this.deliveryMethod = this.deliveryMethods[0];
            this.toggleDeliveryMethod();
          }
          this.$loading(false);
        })
        .catch((error) => {
          console.error('There was an error fetching the delivery methods:', error);
          this.$loading(false);
        });
    },

    loadShopAddress() {
      this.$loading(true);
      axios.get(`address?shop=${this.cart.shopId}`).then((response) => {
        this.shopAddresses = response.data;
        if (this.shopAddresses.length > 0) {
          this.pickUpShop = this.shopAddresses[0];
          this.selectedPickUpShopId = this.pickUpShop.id;
          this.showShopAddress();
        }
        this.$loading(false);
      }).catch((error) => {
        console.error("Error loading shop addresses:", error);
        this.$loading(false);
      });
    },

    showShopAddress() {
      this.pickUpShop = this.shopAddresses.find(
        (shopAddress) => shopAddress.id === this.selectedPickUpShopId
      );
    },

    toggleBillingIsShippingAddress() { },

    toggleDeliveryMethod() {
      if (this.isPickUp) {
        this.loadShopAddress();
      }
      // this.shippingAddress = 'new';
      // this.canAddAddress =  true;
    },

    toggleSelectedBillingAddress() {
      this.billingAddress = this.selectedBillingAddress;
      this.canAddBillingAddress = false;
    },

    toggleSelectedAddress() {
      this.shippingAddress = this.selectedAddress;
      this.canAddAddress = false;
    },

    toggleAddAddress() {
      this.shippingAddress = "new";
      this.canAddAddress = true;
    },

    toggleAddBillingAddress() {
      this.billingAddress = "new_billing_address";
      this.canAddBillingAddress = true;
    },

    getBillingAddress() {
      axios.get("address?type=billing").then((response) => {
        this.billingAddresses = response.data;
        if (this.billingAddresses.length > 0) {
          this.billingAddress = this.billingAddresses[0].id;
          this.selectedBillingAddress = this.billingAddresses[0].id;
          this.canAddBillingAddress = false;
        }
      });
    },

    // hasProduct() {
    //   return this.cart.cartItems(item => item.type === 'product');
    // },

    getShippingAddress() {
      axios.get("address?type=shipping").then((response) => {
        this.addresses = response.data;
        if (this.addresses.length > 0) {
          this.shippingAddress = this.addresses[0].id;
          this.selectedAddress = this.addresses[0].id;
          this.canAddAddress = false;
        }
      });
    },

    saveOrder() {
      this.$loading(true);
      let url = "orders/create";
      if (this.type === 'express') {
        url = "orders/express/create";
        this.billingAddress = "null";
      }

      const data = {
        total_amount: this.cart.total,
        delivery_fee: 0,
        shop_id: this.cart.shopId,
        order_items: this.cart.cartItems,
        notes: this.order.order_notes,
        shipping: { id: this.shippingAddress, ...this.shipping },
        billing: { id: this.billingAddress, ...this.billing },
        pick_up_address_id: this.pickUpShop ? this.pickUpShop.id : null,
        delivery_method_id: this.deliveryMethod ? this.deliveryMethod.id : null,
      };
      axios
        .post(url, data)
        .then((response) => {
          this.$loading(false);
          if (response.status === 200 || response.status === 201) {
            this.$toasted.success("Order created successfully.");
            // this.$store.dispatch("cart/clearCart");
            if (this.type === 'express') {
              this.$router.push({
                name: 'express',
                params: { id: response.data.id },
              });
            } else {
              this.$router.push({
                name: "payment",
                params: { id: response.data.id },
              });
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.message =
              "Order could not be created. Please enter all required fields.";
            this.$toasted.error(this.message);
            this.errors = error.response.data.errors;
          } else if (error.response.status === 401) {
            this.message = "Order could not be created.";
            this.$toasted.error(this.message);
            this.errors = error.response.data.errors;
            this.$router.push({ name: "login" });
          } else {
            console.log("Failed to save the order");
            this.$toasted.error(error.backendErrors[0]);
            this.message = error.backendErrors[0];
          }
          this.isError = true;
          this.$loading(false);
        });
    },
  },
};
</script>

<style scoped>
.ps-block__content {
  padding: 30px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 2px solid #eaeaea;
}

.ps-section--checkout {
  margin-top: 50px;
}
</style>
